import React from 'react'
import { Link } from 'gatsby'
import {css, keyframes} from '@emotion/core'

// import { FaLinkedin, FaGithub } from 'react-icons/fa'

const bounce = keyframes`
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    60% {
        transform: translateY(-10px);
    }
    to {
        transform: translate(0);
        opacity: 1;
    }
`;

const pageTransition = css`
    transform-origin:center;
    animation: ${bounce} 0.5s ease;
`;

export default ({ page, children }) => (
    <div className='layout'>
        <header>
        { page==='index' ? 
            <h2 id='site-title' class='active'>wagenvoord.dev</h2> :
            <h2 id='site-title'><Link to="/">wagenvoord.dev</Link></h2>
        }
        <nav>
            <Link to="/portfolio" className={page==='portfolio' ? 'active' : ''}>Projects</Link>
            <Link to="/resources" className={page==='resources' ? 'active' : ''}>Resources</Link>
            <Link to="/pltw" className={page==='pltw' ? 'active' : ''}>PLTW Capstone</Link>
            <Link to="/contact" id="contact-cta" className='blue-box'>contact</Link>
        </nav>
        </header>
        <main css={pageTransition} className="shadow">
            { children }
        </main>
        <footer>
            <p>Made with Gatsby and smiles by Isabelle Wagenvoord</p>
            {/* <div className='icons'>
                <FaLinkedin />
                <FaGithub />
            </div> */}
        </footer>
    </div>
)